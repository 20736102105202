import React, { useEffect, useRef, useState } from "react";
import Container from "../../../components/chat-channel/Container";
import { Link } from "gatsby";
import { useMedia } from "use-media";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import {
  AdHomePage_November,
  ISOSecurity_November,
} from "../../conversational-ai-platform";
import {
  Header,
  Footer,
  TopHeadSecPaidLanding,
  TabsSection,
  ChatbotsCategories,
  ClientTestimonials,
  BookDemoCalendly,
  demoData,
  tabContent,
  heroImage,
  chatbotApps,
  handleClickBookDemo,
} from "./itsm-chatbot";

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Workativ",
  url: "https://workativ.com",
  description:
    " Workativ Assistant is easy to use 'AI + Automation' SaaS product for companies to build an intelligent Virtual Assistant/Chatbot with pre-built automations, to provide employee support, reduce Help Desk costs, and increase productivity. No coding required! ",
  sameAs: [
    "https://www.youtube.com/channel/UCDNRPFuv8tI7XDm117XV9eg",
    "https://twitter.com/workativ",
    "https://www.facebook.com/workativ",
  ],
  sourceOrganization: {
    "@type": "Organization",
    name: "Workativ Software Solutions",
    description:
      " Workativ offers an intelligent AI + Automation platform to build and deploy purpose-built Chatbot for workplace support. ",
    url: "https://www.workativ.com",
    logo: "https://workativ.com/workativ-logo.196a2d6c.png",
    founder: {
      "@type": "Person",
      name: "Imran Quraishy",
    },
    sameAs: ["https://www.linkedin.com/company/workativ-com/"],
  },
};
export default function HomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Workativ Assistant: Your Moveworks Alternative"
        description="Explore Workativ Assistant, the Moveworks alternative that streamlines employee support with advanced automation."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Service Desk Chatbot",
          "Moveworks Alternative",
        ]}
        homepagrUrl
        schema={ldSchema}
      />
      <div className="">
        <Container>
          <Header />
          <TopHeadSecPaidLanding
            heading={
              "Automate your Employee Support with Gen AI Chatbot Automation"
            }
            buttonText={"Book a demo"}
            heroImage={heroImage}
            onButtonClick={handleClickBookDemo}
          />
          <TabsSection
            tabContent={tabContent}
            onButtonClick={handleClickBookDemo}
          />
          <ChatbotsCategories
            heading={"Integrate chatbot with app workflows in seconds."}
            description={
              "Pick from over 1000+ pre-built automations on popular apps, and automate your employee support."
            }
            appsImage={chatbotApps}
          />
          <AdHomePage_November />
          <AppComparison
            backgroundColorOne={"#FFE9D3"}
            backgroundColorTwo={"#CCFFEC"}
            header={"See how Workativ stacks up against the competition"}
            subHeader={
              "Enterprise Support Automation Category | Gen AI Chatbot Platform"
            }
            tableData={dataForTable}
          />
          <ClientTestimonials
            withoutButton={true}
            onButtonClick={handleClickBookDemo}
          />

          <BookDemoCalendly
            heading={
              "See Workativ’s 4-in-1 Gen AI support automation platform in action"
            }
            subHeading={"BOOK A 30-MINUTE DEMO"}
          />
          <ISOSecurity_November noLink={true} />
          <Footer />
        </Container>
      </div>
    </>
  );
}

export function AppComparison({
  backgroundColorOne,
  backgroundColorTwo,
  header,
  subHeader,
  tableData,
  margin,
}) {
  return (
    <section
      className={`w-100 float-left table__section__paid__ad ${
        margin ? margin : ""
      }`}
    >
      <div className="container">
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h2 className="font-section-sub-header text-align-center">
            {header}
          </h2>
          <p className="font-section-normal-text text-align-center">
            {subHeader}
          </p>
        </div>
        <div className="box__paid__ad__table">
          <table className="paid__ad__table w-100">
            <thead className="paid__ad__thead">
              <tr className="paid__ad__thead__tr">
                {tableData.map((item, index) => (
                  <th
                    key={index}
                    style={{
                      backgroundColor:
                        (index === 1 && backgroundColorOne) ||
                        (index === 3 && backgroundColorTwo),
                    }}
                    className={`font-section-sub-header-small-home ${
                      index !== 0 && index !== 2
                        ? "table__heading__paid__ad"
                        : ""
                    } ${index === 2 ? "empty__element__heading" : ""}`}
                  >
                    {item.heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="paid__ad__tbody__tr">
              {tableData[0].contents.map((content, index) => (
                <tr key={index} className="paid__ad__tbody__tr">
                  <td
                    className={`table__description__paid__ad font-section-normal-text-testimonials-medium ${
                      index === tableData[0].contents.length - 1
                        ? "last__box__table__only__padding"
                        : ""
                    }`}
                  >
                    {content}
                  </td>
                  {tableData.slice(1).map((row, rowIndex) => (
                    <td
                      key={rowIndex}
                      style={{
                        backgroundColor:
                          rowIndex === 0
                            ? backgroundColorOne
                            : rowIndex === 2
                            ? backgroundColorTwo
                            : "",
                      }}
                      className={`font-section-normal-text-testimonials ${
                        rowIndex !== 1 ? "table__description__paid__ad" : ""
                      } ${
                        rowIndex === 1 ? "empty__element__description" : ""
                      } ${
                        (rowIndex === 0 || rowIndex === 2) &&
                        index === row.contents.length - 1
                          ? "last__box__table"
                          : ""
                      }`}
                      dangerouslySetInnerHTML={{ __html: row.contents[index] }}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

const dataForTable = [
  {
    heading: "",
    contents: [
      "Ease of Admin",
      "Ease of Customizations",
      "App Workflow Automation",
      "Knowledge AI",
      "Multi-channel Live Chat Inbox",
    ],
  },
  {
    heading: "Moveworks",
    contents: [
      "Excellent",
      "Good",
      "via custom API connectors",
      "Enterprise Search",
      "Not available",
    ],
  },
  {
    heading: "",
    contents: ["", "", "", "", ""],
  },
  {
    heading: "Workativ",
    contents: [
      "Excellent",
      "Full flexibility via no-code platform",
      "via no-code app workflow <br/> automation builder",
      "Knowledge AI GPT",
      "AI-powered Live Chat Inbox",
    ],
  },
];
